import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store/store.js";
import axiosConfig from "@/api/axiosConfig";
import AppLayout from "@/components/AppLayout";

const routes = [
  {
    path: "/layout",
    name: "layout",
    component: AppLayout,
    children: [
      {
        path: "/pdf",
        name: "PDF",
        meta: { title: "PDF", requiresAuth: false },
        component: () => import("@/views/PDF.vue"),
      },
      {
        path: "/ltl",
        name: "LTL",
        meta: { title: "LTL", requiresAuth: true },
        component: () => import("@/views/LTL.vue"),
      },
      {
        path: "/ftl",
        name: "FTL",
        meta: { title: "FTL", requiresAuth: false },
        component: () => import("@/views/FTL.vue"),
      },
      {
        path: "/factoring-company",
        name: "FactoringCompany",
        meta: { title: "Factoring Company", requiresAuth: true },
        component: () => import("@/views/FactoringCompany.vue"),
      },
      {
        path: "/my-account",
        name: "MyAccount",
        meta: { title: "My Account", requiresAuth: true },
        component: () => import("@/views/MyAccount.vue"),
      },
      {
        path: "/contact",
        name: "Contact",
        meta: { title: "Contact", requiresAuth: false },
        component: () => import("@/views/Contact.vue"),
      },
      {
        path: "/available-loads",
        name: "AvailableLoads",
        meta: { title: "AvailableLoads", requiresAuth: true },
        component: () => import("@/views/AvailableLoads.vue"),
      },
      {
        path: "/my-loads",
        name: "MyLoads",
        meta: { title: "MyLoads", requiresAuth: true },
        component: () => import("@/views/MyLoads.vue"),
      },
      {
        path: "/home",
        name: "Home",
        meta: {
          title: "Home Page",
          requiresAuth: true,
        },
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/upload-document",
        name: "UploadDocument",
        meta: {
          title: "Upload Document",
          requiresAuth: false,
        },
        component: () => import("@/views/UploadDocument.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "Login",
      requiresAuth: false,
    },
    component: () => import("@/views/Login.vue"),
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   meta: {
  //     title: "Register Page",
  //     requiresAuth: false,
  //   },
  //   component: () => import("@/views/Register.vue"),
  // },
  {
    path: "/",
    name: "Landing",
    meta: {
      title: "Landing Page",
      requiresAuth: false,
    },
    component: () => import("@/views/Landing.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue"),
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  document.title = "LDI Carrier Portal |" + " " + to.meta.title;
  if (!store.state.environment.Fetched) {
    await store.dispatch("environment/FetchEnvironmentVars");
    axiosConfig.defaults.baseURL = store.getters["environment/APIUrl"];
  }
  if (to.meta.requiresAuth == true && store.state.login.authed === false) {
    return {
      path: "/login",
    };
  }
});

export default router;
