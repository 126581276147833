import axiosConfig from "@/api/axiosConfig.js";

const dataProviderContact = {
  async contact(name, mc, email, phone, help, comments) {
    let result = await axiosConfig.post("/contactus/contact", {
      CarrierID: 0,
      CarrierPortalUserID: 0,
      Name: name,
      MCUSNumber: mc,
      EmailAddress: email,
      PhoneNumber: phone,
      Reason: help,
      Message: comments,
    });

    return result.data;
  },
};
export { dataProviderContact };
