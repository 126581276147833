import axiosConfig from "@/api/axiosConfig.js";

const dataProviderRegister = {
  async register(form) {
    let result = await axiosConfig.post("/webauth/register-user", {
      FirstName: form.FirstName,
      LastName: form.LastName,
      CompanyName: form.CompanyName,
      JobTitle: form.JobTitle,
      CompanyAddress: form.CompanyAddress,
      City: form.City,
      State: form.State,
      PostalCode: form.PostalCode,
      PhoneNumber: form.PhoneNumber,
      FaxNumber: form.FaxNumber,
      EmailAddress: form.EmailAddress,
      confirmemail: form.confirmemail,
      Password: form.Password,
      confirmpassword: form.confirmpassword,
      MCNumber: form.MCNumber,
      Subscribe: form.Subscribe,
    });

    return result.data;
  },
};
export { dataProviderRegister };
