import { dataProviderLogin } from "@/api/dataProviderLogin";

const login = {
  namespaced: true,
  state: () => ({
    AddressLine1: null,
    CarrierID: 0,
    CarrierPortalUserID: 0,
    City: null,
    CompanyName: null,
    EmailAddress: null,
    FaxNumber: null,
    FirstName: null,
    JobTitle: null,
    LastName: null,
    MCNumber: null,
    PhoneNumber: null,
    State: null,
    Username: null,
    token: null,
    refreshToken: null,
    authed: false,
    ZipCode: null
  }),

  mutations: {
    setLogin(state, payload) {
      (state.ZipCode = payload.ZipCode),
        (state.AddressLine1 = payload.AddressLine1),
        (state.CarrierID = payload.CarrierID),
        (state.CarrierPortalUserID = payload.CarrierPortalUserID),
        (state.City = payload.City),
        (state.CompanyName = payload.CompanyName),
        (state.EmailAddress = payload.EmailAddress),
        (state.FaxNumber = payload.FaxNumber),
        (state.FirstName = payload.FirstName),
        (state.JobTitle = payload.JobTitle),
        (state.LastName = payload.LastName),
        (state.MCNumber = payload.MCNumber),
        (state.PhoneNumber = payload.PhoneNumber),
        (state.State = payload.State),
        (state.Username = payload.Username),
        (state.authed = payload.authed);
      (state.token = payload.token),
        (state.refreshToken = payload.refreshToken);
    },

    clearLogin(state) {
      (state.ZipCode = ""),
        (state.AddressLine1 = ""),
        (state.CarrierID = 0),
        (state.CarrierPortalUserID = 0),
        (state.City = ""),
        (state.CompanyName = ""),
        (state.EmailAddress = ""),
        (state.FaxNumber = ""),
        (state.FirstName = ""),
        (state.JobTitle = ""),
        (state.LastName = ""),
        (state.MCNumber = ""),
        (state.PhoneNumber = ""),
        (state.State = ""),
        (state.Username = ""),
        (state.authed = false);
      state.token = "";
      state.refreshToken = false;
    },
  },

  actions: {
    async TryDoLogin({ commit }, { email, password }) {
      try {
        let response = await dataProviderLogin.login(
          email,
          password
        );

        let payload = {
          ZipCode: response.carrierAccountUser.ZipCode,
          AddressLine1: response.carrierAccountUser.AddressLine1,
          CarrierID: response.carrierAccountUser.CarrierID,
          CarrierPortalUserID: response.carrierAccountUser.CarrierPortalUserID,
          City: response.carrierAccountUser.City,
          CompanyName: response.carrierAccountUser.CompanyName,
          EmailAddress: response.carrierAccountUser.EmailAddress,
          FaxNumber: response.carrierAccountUser.FaxNumber,
          FirstName: response.carrierAccountUser.FirstName,
          JobTitle: response.carrierAccountUser.JobTitle,
          LastName: response.carrierAccountUser.LastName,
          MCNumber: response.carrierAccountUser.MCNumber,
          PhoneNumber: response.carrierAccountUser.PhoneNumber,
          State: response.carrierAccountUser.State,
          Username: response.carrierAccountUser.Username,
          token: response.token,
          refreshToken: response.refreshToken,
          authed: true,
        };
        commit("setLogin", payload);

        return response;
      } catch (err) {
        if (err.response.status === 403) {
          throw { combinemessage: err.response.data, status: 403 };
        }
        if (err.response.status === 400) {
          throw { message: err.response.data, status: 400 };
        }
      }
    },
  },
  getter: {
    isAuthed(state) {
      return state.authed == true ? true : false;
    },
  },
};

export default login;
