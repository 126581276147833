import axios from "axios";
import { store } from "@/store/store.js";

// Base axios config object
const axiosConfig = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// Promise is used when multiple reqeuests are sent out to stop multiple refresh token calls	
// (this causes tokens to be immeaditly revoked on creation)	
let refreshTokenPromise;
const getRefreshToken = () =>
  axiosConfig.post("/webauth/refresh", {
    code: store.state.login.refreshToken 
  });
// Use request interceptor to attach access token from Vuex store
axiosConfig.interceptors.request.use(
  (config) => {
    if (store.state.login.authed === true) {
      config.headers["Authorization"] = `Bearer ${store.state.login.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Use response interceptor for API calls to fetch new access token if existing is invalid

axiosConfig.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    var errorMessage = "";
    if (error.message == "Network Error") {
      errorMessage = "API server not responding";
      alert(errorMessage);
    }
    //handle API error -Internal server error 500		
    if (error.response.status == 500) {
      errorMessage =
        "Internal server error on API: " +
        error.response.config.url +
        " \n\n error details: " +
        error.response.data;
      //this alert is dev only, will remove laterb on		
      alert(errorMessage);
    }
    const originalRequest = error.config;
    // Check to prevent infinite loop of requests		
    if (
      error.response.status === 401 &&
      originalRequest.url === "/webauth/refresh"
    ) {
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // if the refreshTokenPromise obj is null, create a new instance.		
      if (!refreshTokenPromise) {
        refreshTokenPromise = getRefreshToken().then(response => {
          refreshTokenPromise = null;
          return response;
        });
      }
      // otherwise return the existing instance		
      return refreshTokenPromise
        .then(response => {
          store.commit("login/setLogin", {
            ZipCode: response.data.carrierAccountUser.ZipCode,
            AddressLine1: response.data.carrierAccountUser.AddressLine1,
            CarrierID: response.data.carrierAccountUser.CarrierID,
            CarrierPortalUserID: response.data.carrierAccountUser.CarrierPortalUserID,
            City: response.data.carrierAccountUser.City,
            CompanyName: response.data.carrierAccountUser.CompanyName,
            EmailAddress: response.data.carrierAccountUser.EmailAddress,
            FaxNumber: response.data.carrierAccountUser.FaxNumber,
            FirstName: response.data.carrierAccountUser.FirstName,
            JobTitle: response.data.carrierAccountUser.JobTitle,
            LastName: response.data.carrierAccountUser.LastName,
            MCNumber: response.data.carrierAccountUser.MCNumber,
            PhoneNumber: response.data.carrierAccountUser.PhoneNumber,
            State: response.data.carrierAccountUser.State,
            Username: response.data.carrierAccountUser.Username,
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            authed: true
          });
          return axiosConfig(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);
export default axiosConfig;
