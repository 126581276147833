import axiosConfig from "@/api/axiosConfig.js";

const dataProviderUpload = {
  async upload(form) {
    let result = await axiosConfig.post("/loads/load-details", {
      CarrierID: form.CarrierID,
      CarrierPortalUserID: form.CarrierPortalUserID,
      EmailAddress: form.EmailAddress,
      LoadID: form.LoadID,
      MCUSNumber: form.MCUSNumber,
    });

    return result.data;
  },
};
export { dataProviderUpload };
