import { dataProviderContact } from "@/api/dataProviderContact";

const contact = {
  namespaced: true,
  state: () => ({}),

  mutations: {
    setContact(state, payload) {
      state.name = payload.name;
      state.mc = payload.mc;
      state.email = payload.email;
      state.phone = payload.phone;
      state.help = payload.help;
      state.comments = payload.comments;
    },
  },

  actions: {
    async TryDoContact({ commit }, { name, mc, email, phone, help, comments }) {
      try {
        let response = await dataProviderContact.contact(
          name,
          mc,
          email,
          phone,
          help,
          comments
        );

        let payload = {
          name: response.name,
          mc: response.mc,
          email: response.email,
          phone: response.phone,
          help: response.help,
          comments: response.comments,
        };
        commit("setContact", payload);

        return response;
      } catch (err) {
        if (err.response.status === 403) {
          throw { combinemessage: err.response.data, status: 403 };
        }
        if (err.response.status === 400) {
          throw { message: err.response.data, status: 400 };
        }
      }
    },
  },
  getter: {},
};

export default contact;
